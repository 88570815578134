import React, { useState } from "react"
import styled from "styled-components"
import { Grid, useMediaQuery } from "@material-ui/core/"

import MapComponent from "./map"

import { bratDetails } from "../../text-files/homepageText"
import marketIcon from "../../images/home/marketicon.png"
import mapmarkerA from "../../images/home/map_marker_A.png"
import mapmarkerB from "../../images/home/map_marker_B.png"
import mapmarkerC from "../../images/home/map_marker_C.png"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 20px;
  margin-bottom: 72px;
  background-color: var(--eggshell);
  border-radius: 25px 25px 0 0;
  font-size: 22px;
`

const Header = styled.div`
  border-radius: 25px 25px 0 0;
  background-color: var(--main-color);
  width: 100%;
  color: white;
  text-align: center;
  padding: 5px;
  font-size: 22px;
  font-family: "thunder";
`

const MarketIcon = styled.img`
  height: 70px;
  margin-top: -70px;
`

const VendorContainer = styled.div`
  width: 100%;
`

const InfoContainer = styled.div`
  padding: 20px;
`

const InfoContainerGrid = styled(Grid)`
  padding: 5px 0;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.1s ease-in-out;

  &:hover {
    background: #d3cbb4;
    transform: scale(0.99);
  }

  }
`

const Times = styled.p`
  font-size: 18px;
`

const VendorName = styled.h1`
  width: 100%;
  font-size: 42px;
  margin-bottom: -5px;
  text-align: center;
`

const VendorSubTitle = styled.p`
  text-align: center;
  padding-bottom: 10px;
`

const MapMarker = styled.img`
  height: 70px;
  margin-left: -5px;

  @media (max-width: 768px) {
    margin-left: -40%;
  }
`

const Address = styled.div`
  width: 100%;
  text-align: right;
`

const LocationsComponent = () => {
  const matches = useMediaQuery("(max-width:960px)")

  const initialView = {
    loc: {
      lat: 51.47116539815708,
      lng: -0.204846863438927,
    },
    zoom: {
      z: 11,
    },
  }

  const [mapView, setMapView] = useState(initialView)

  const handleLocClick = loc => {
    loc === 0
      ? setMapView({
          // merton apprentice
          loc: {
            lat: 51.413216370457675,
            lng: -0.183425847287169,
          },

          zoom: {
            z: 15,
          },
        })
      : loc === 1
      ? setMapView({
          // acton market
          loc: {
            lat: 51.50799771795835,
            lng: -0.27228767069153964,
          },
          zoom: {
            z: 15,
          },
        })
      : loc === 2
      ? setMapView({
          // north end road
          loc: {
            lat: 51.48258,
            lng: -0.20022,
          },
          zoom: {
            z: 15,
          },
        })
      : setMapView({
          loc: {
            lat: 51.47088816497439,
            lng: -0.2521988042378051,
          },
          zoom: {
            z: 15,
          },
        })

    setTimeout(() => {
      setMapView(initialView)
    }, 15000)
  }

  const mapStyles = matches
    ? {
        width: "100%",
        height: "400px",
      }
    : { width: "100%", height: "300px" }

  return (
    <Container>
      <MarketIcon src={marketIcon} alt="" />
      <Header>
        <h1>LOCATIONS</h1>
      </Header>
      <VendorContainer>
        <InfoContainer>
          <VendorName>BRATHAUS</VendorName>
          <VendorSubTitle>
            ({matches ? "tap" : "click"} on vendor to zoom)
          </VendorSubTitle>
          <InfoContainerGrid container onClick={() => handleLocClick(0)}>
            <Grid item xs={1}>
              <MapMarker src={mapmarkerA} alt="" />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <strong>{bratDetails.merton.name}</strong>
              <br />
              <Times>{bratDetails.merton.days}</Times>
              <Times>{bratDetails.merton.hours}</Times>
            </Grid>
            <Grid item xs={5}>
              <Address>
                <p>
                  {bratDetails.merton.address[0]}
                  <br />
                  {bratDetails.merton.address[1]}
                </p>
              </Address>
            </Grid>
          </InfoContainerGrid>
          <InfoContainerGrid container onClick={() => handleLocClick(1)}>
            <Grid item xs={1}>
              <MapMarker src={mapmarkerB} alt="" />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <strong>{bratDetails.acton.name}</strong>
              <br />
              <Times>{bratDetails.acton.days}</Times>
              <Times>{bratDetails.acton.hours}</Times>
            </Grid>
            <Grid item xs={5}>
              <Address>
                <p>
                  {bratDetails.acton.address[0]}
                  <br />
                  {bratDetails.acton.address[1]}
                </p>
              </Address>
            </Grid>
          </InfoContainerGrid>
          <InfoContainerGrid container onClick={() => handleLocClick(2)}>
            <Grid item xs={1}>
              <MapMarker src={mapmarkerC} alt="" />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <strong>{bratDetails.north.name}</strong>
              <br />
              <Times>{bratDetails.north.days}</Times>
              <Times>{bratDetails.north.hours}</Times>
              <Times style={{ color: "red" }}>Temporarily closed</Times>
            </Grid>
            <Grid item xs={5}>
              <Address>
                <p>
                  {bratDetails.north.address[0]}
                  <br />
                  {bratDetails.north.address[1]}
                </p>
              </Address>
            </Grid>
          </InfoContainerGrid>
        </InfoContainer>

        <MapComponent mapStyles={mapStyles} mapView={mapView} />
      </VendorContainer>
    </Container>
  )
}

export default LocationsComponent
